import React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Spinner } from "@chakra-ui/react"
import AppSettingsTest from './app-settings-test';

export default function CoreHeader({compStore, setCompStore, appSettings="a", banners="b", billingInformation="C", additionalTreesAndBottles="d"}) {
    const [showSpinner, setShowSpinner] = React.useState(false);



    return (
<Tabs variant="soft-rounded" colorScheme="teal">
<TabList py={6}>
    <Tab>App settings</Tab>
    <Tab>Banners</Tab>
    <Tab>Contribute Additional Trees and Bottles</Tab>
  </TabList>
      
        <TabPanels>
          <TabPanel>
            {appSettings}
          </TabPanel>
          <TabPanel>
            {banners}
          </TabPanel>
          <TabPanel>
            {additionalTreesAndBottles}
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
}