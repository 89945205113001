import React from 'react';
import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Alert,
  AlertIcon,
  Divider,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Spinner,
  Center,
  Icon
} from "@chakra-ui/react";
import CustomSproutButton from './CustomSproutButton';
import { CheckIcon } from '@chakra-ui/icons';
import { PurchaseAdditionalImpact } from '../backend';
import firebase from '../firebase';

function AdditionalImpactForm({ compStore, setCompStore }) {
  const [treeCount, setTreeCount] = React.useState(5);
  const [bottleCount, setBottleCount] = React.useState(10);
  const [treeError, setTreeError] = React.useState("");
  const [bottleError, setBottleError] = React.useState("");
  const [purchaseError, setPurchaseError] = React.useState("");
  const [isTreeDialogOpen, setIsTreeDialogOpen] = React.useState(false);
  const [isBottleDialogOpen, setIsBottleDialogOpen] = React.useState(false);
  const cancelRef = React.useRef();
  const [isTreePurchasing, setIsTreePurchasing] = React.useState(false);
  const [isBottlePurchasing, setIsBottlePurchasing] = React.useState(false);
  const [treeSuccess, setTreeSuccess] = React.useState(false);
  const [bottleSuccess, setBottleSuccess] = React.useState(false);

  const validateTrees = (value) => {
    if (isNaN(value) || value < 5) {
      setTreeError("Minimum of 5 trees required");
      return false;
    }
    setTreeError("");
    return true;
  };

  const validateBottles = (value) => {
    if (isNaN(value) || value < 10) {
      setBottleError("Minimum of 10 bottles required");
      return false;
    }
    setBottleError("");
    return true;
  };

  const handleTreePurchase = async () => {
    setPurchaseError("");
    setIsTreeDialogOpen(true);
  };

  const handleBottlePurchase = async () => {
    setPurchaseError("");
    setIsBottleDialogOpen(true);
  };

  const confirmTreePurchase = async () => {
    setIsTreePurchasing(true);
    setPurchaseError("");
    try {
      const token = await firebase.auth().currentUser.getIdToken(true);
      const success = await PurchaseAdditionalImpact(
        token, 
        compStore.current_integration.uuid,
        treeCount, 
        0
      );
      
      if (!success) {
        throw new Error('Purchase failed');
      }

      setIsTreePurchasing(false);
      setTreeSuccess(true);
      setTimeout(() => {
        setIsTreeDialogOpen(false);
        setTreeSuccess(false);
        if (treeSuccess) {
          window.location.reload();
        }
      }, 2000);
    } catch (error) {
      console.error('Error purchasing trees:', error);
      setIsTreePurchasing(false);
      setPurchaseError("Purchase could not be completed. Please reach out to support on the chat bubble on the bottom right or to saif@thegoodapi.com for further support");
    }
  };

  const confirmBottlePurchase = async () => {
    setIsBottlePurchasing(true);
    setPurchaseError("");
    try {
      const token = await firebase.auth().currentUser.getIdToken(true);
      const success = await PurchaseAdditionalImpact(
        token,
        compStore.current_integration.uuid,
        0,
        bottleCount
      );
      
      if (!success) {
        throw new Error('Purchase failed');
      }

      setIsBottlePurchasing(false);
      setBottleSuccess(true);
      setTimeout(() => {
        setIsBottleDialogOpen(false);
        setBottleSuccess(false);
        if (bottleSuccess) {
          window.location.reload();
        }
      }, 2000);
    } catch (error) {
      console.error('Error purchasing bottles:', error);
      setIsBottlePurchasing(false);
      setPurchaseError("Purchase could not be completed. Please reach out to support on the chat bubble on the bottom right or to saif@thegoodapi.com for further support");
    }
  };

  if (compStore.current_integration?.status !== "active") {
    return (
      <Box p={6} bg="#F4F8FB" borderRadius="24">
        <Alert status="warning" borderRadius="md">
          <AlertIcon />
          <Text>Please activate your integration in App Settings first to purchase additional environmental impact.</Text>
        </Alert>
      </Box>
    );
  }

  return (
    <Box p={6} bg="#F4F8FB" borderRadius="24">
      <VStack spacing={6} align="stretch">
        {/* Trees Input */}
        <FormControl>
          <FormLabel fontWeight="medium">
            Additional Trees to Plant
          </FormLabel>
          <Text mb={2} color="gray.600" fontSize="sm">
            ${compStore.costPerTree.toFixed(2)} USD per tree
          </Text>
          <NumberInput
            value={treeCount}
            min={0}
            onChange={(valueString) => {
              const value = valueString === '' ? 0 : parseInt(valueString);
              setTreeCount(value);
              validateTrees(value);
            }}
            keepWithinRange={true}
            clampValueOnBlur={true}
          >
            <NumberInputField bgColor="white" borderRadius="10" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          {treeError && (
            <Alert status="error" mt={2} borderRadius="md" size="sm">
              <AlertIcon />
              <Text fontSize="sm">{treeError}</Text>
            </Alert>
          )}
          <Text mt={2} color="gray.600" fontSize="sm">
            Total: ${(treeCount * compStore.costPerTree).toFixed(2)} USD
          </Text>
          <Box mt={4}>
            <CustomSproutButton
              compStore={compStore}
              setCompStore={setCompStore}
              label="Purchase Trees"
              color="teal"
              handler={handleTreePurchase}
              isDisabled={!!treeError || treeCount < 5 || compStore.current_integration?.status !== "active"}
              ignoreToast={true}
            />
          </Box>
        </FormControl>

        <Divider my={4} />

        {/* Plastic Bottles Input */}
        <FormControl>
          <FormLabel fontWeight="medium">
            Additional Plastic Bottles to Remove
          </FormLabel>
          <Text mb={2} color="gray.600" fontSize="sm">
            ${compStore.costPerBottle.toFixed(2)} USD per bottle
          </Text>
          <NumberInput
            value={bottleCount}
            min={0}
            onChange={(valueString) => {
              const value = valueString === '' ? 0 : parseInt(valueString);
              setBottleCount(value);
              validateBottles(value);
            }}
            keepWithinRange={true}
            clampValueOnBlur={true}
          >
            <NumberInputField bgColor="white" borderRadius="10" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          {bottleError && (
            <Alert status="error" mt={2} borderRadius="md" size="sm">
              <AlertIcon />
              <Text fontSize="sm">{bottleError}</Text>
            </Alert>
          )}
          <Text mt={2} color="gray.600" fontSize="sm">
            Total: ${(bottleCount * compStore.costPerBottle).toFixed(2)} USD
          </Text>
          <Box mt={4}>
            <CustomSproutButton
              compStore={compStore}
              setCompStore={setCompStore}
              label="Purchase Bottles"
              color="teal"
              handler={handleBottlePurchase}
              isDisabled={!!bottleError || bottleCount < 10 || compStore.current_integration?.status !== "active"}
              ignoreToast={true}
            />
          </Box>
        </FormControl>

        <Box mt={4} p={4} bg="gray.50" borderRadius="md">
          <Text fontSize="sm" color="gray.600">
            Note: Your impact will be reflected immediately on all counters and banners across your store.
          </Text>
        </Box>
      </VStack>

      {/* Tree Purchase Confirmation Dialog */}
      <AlertDialog
        isOpen={isTreeDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          if (!isTreePurchasing) {
            if (treeSuccess) {
              window.location.reload();
            }
            setIsTreeDialogOpen(false);
            setTreeSuccess(false);
            setPurchaseError("");
          }
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Tree Purchase
            </AlertDialogHeader>

            <AlertDialogBody>
              {purchaseError ? (
                <Alert status="error" mt={2} borderRadius="md">
                  <AlertIcon />
                  <Text fontSize="sm">{purchaseError}</Text>
                </Alert>
              ) : isTreePurchasing ? (
                <Center py={6}>
                  <Spinner size="xl" color="teal.500" thickness="4px" />
                </Center>
              ) : treeSuccess ? (
                <Center py={6} flexDirection="column">
                  <Icon as={CheckIcon} w={12} h={12} color="teal.500" mb={4} />
                  <Text fontSize="xl" fontWeight="medium" textAlign="center">
                    Success! Impact registered.
                  </Text>
                </Center>
              ) : (
                <>
                  <Text>
                    Confirm a purchase of {treeCount} trees @ ${compStore.costPerTree.toFixed(2)} per tree.
                  </Text>
                  <Text mt={2} fontWeight="medium">
                    Total: ${(treeCount * compStore.costPerTree).toFixed(2)} USD
                  </Text>
                  <Text mt={4} fontSize="sm" color="gray.600">
                    Impact will register immediately and the charge will appear on your regular monthly bill.
                  </Text>
                </>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              {!isTreePurchasing && !treeSuccess && !purchaseError && (
                <>
                  <Button ref={cancelRef} onClick={() => setIsTreeDialogOpen(false)}>
                    Cancel
                  </Button>
                  <Button colorScheme="teal" onClick={confirmTreePurchase} ml={3}>
                    Confirm Purchase
                  </Button>
                </>
              )}
              {purchaseError && (
                <Button ref={cancelRef} onClick={() => setIsTreeDialogOpen(false)}>
                  Close
                </Button>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Bottle Purchase Confirmation Dialog */}
      <AlertDialog
        isOpen={isBottleDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          if (!isBottlePurchasing) {
            if (bottleSuccess) {
              window.location.reload();
            }
            setIsBottleDialogOpen(false);
            setBottleSuccess(false);
            setPurchaseError("");
          }
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Bottle Purchase
            </AlertDialogHeader>

            <AlertDialogBody>
              {purchaseError ? (
                <Alert status="error" mt={2} borderRadius="md">
                  <AlertIcon />
                  <Text fontSize="sm">{purchaseError}</Text>
                </Alert>
              ) : isBottlePurchasing ? (
                <Center py={6}>
                  <Spinner size="xl" color="teal.500" thickness="4px" />
                </Center>
              ) : bottleSuccess ? (
                <Center py={6} flexDirection="column">
                  <Icon as={CheckIcon} w={12} h={12} color="teal.500" mb={4} />
                  <Text fontSize="xl" fontWeight="medium" textAlign="center">
                    Success! Impact registered.
                  </Text>
                </Center>
              ) : (
                <>
                  <Text>
                    Confirm a purchase of {bottleCount} bottles @ ${compStore.costPerBottle.toFixed(2)} per bottle.
                  </Text>
                  <Text mt={2} fontWeight="medium">
                    Total: ${(bottleCount * compStore.costPerBottle).toFixed(2)} USD
                  </Text>
                  <Text mt={4} fontSize="sm" color="gray.600">
                    Impact will register immediately and the charge will appear on your regular monthly bill.
                  </Text>
                </>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              {!isBottlePurchasing && !bottleSuccess && !purchaseError && (
                <>
                  <Button ref={cancelRef} onClick={() => setIsBottleDialogOpen(false)}>
                    Cancel
                  </Button>
                  <Button colorScheme="teal" onClick={confirmBottlePurchase} ml={3}>
                    Confirm Purchase
                  </Button>
                </>
              )}
              {purchaseError && (
                <Button ref={cancelRef} onClick={() => setIsBottleDialogOpen(false)}>
                  Close
                </Button>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

export default AdditionalImpactForm;