
import React from 'react';
import { useMemo } from 'react';
import {GetAccountSettings, GetAddIntegrationURLWithWebsiteID} from "../backend"
import CoreHeader from './core-header';
import NavBar from './nav-bar';
import { Box , VStack, StackDivider, Container, Flex} from '@chakra-ui/react';
import AppSettings from './app-settings';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { create } from 'zustand'
import AppSettingsTest from './app-settings-test';
import Banners from './Banner';
import { useAuthState } from 'react-firebase-hooks/auth';
import { HTTPGet } from '../httputils';
import { FaPlus } from "react-icons/fa";

import firebase from '../firebase';
import IntegrationModal, { AccessDeniedModal } from './integrationModal';
import AppSettingsV2 from './app-settings-v2';
import AdditionalImpact from './AdditionalImpact';
import Metrics from './metrics';
import Billing from './Billing';
import BillingCheckout from './BillingCheckout';
import BillingInfoAlert from './BillingInfoAlert';
import { GetAddIntegrationURL,  } from '../backend';


import { Spinner } from "@chakra-ui/react";

// ...
function AddIntegrationBox() {
  const [user, loading] = useAuthState(firebase.auth());
  if (!loading && !user) {
    return <Navigate to="/signup" />;
  }

  return (
    <Flex 
      align="center"
      justify="center"
      bg="gray.100"
      w="100%"
      h="200px"
      border="2px dashed gray"
      borderRadius="lg"
      cursor="pointer"
      _hover={{
        bg: "gray.200",
      }}
      flexDirection="column"
      onClick={async () => {
        var url = await GetAddIntegrationURL(user.getIdToken(true), "squarespace");
        window.location.href = url
      }}
      
    >
      <Box textAlign="center">
        <div style={{
          "padding-left": "30%",
          "padding-bottom":"10%"
        }}> 
        <Box as={FaPlus} size="50px" color="gray.500" mb={2} />
        </div>
        <Box fontWeight="bold" mt="-10px">Add First Integration</Box>
      </Box>
    </Flex>
  );
}

function InitialLoading({compStore, setCompStore, isLoading, setIsLoading}) {
  // state to keep track of whether the content is loading or not

  return (
    <div>
      {/* your content here */}
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999"
          }}
        >
          <Spinner size="xl" color="white" />
        </div>
      )}
    </div>
  );
}


export const Home = () => {
      const location = useLocation()
      let stateData = location.state;
  
     
      const [user, loading] = useAuthState(firebase.auth());
      const nav = useNavigate(); // useNavigate should be called inside a component
      const [showIntegetraionModal, setShowIntegrationModal] = React.useState(false);
      const modalRef = React.useRef(null)
      const [isLoading, setIsLoading] = React.useState(true);
      const [showFirstIntegrationBox, setShowFirstIntegrationBox] = React.useState(false);

      const [compStore, setCompStore] = React.useState({
        // updated comp store settings 
        global_account_settings: {
          billing: {
            status: "active",
            last4: "",
          }
        },

        // universe of possible integrations to add 
        available_integrations: [
         
        ],

        // selected integration
        current_integration: {
           
        },


        // integrations that are currently installed
        connected_integrations: [
         
        ],


/////////////////////////// OLD ///////////////////////////
        // app settings page
        selectedTreePlantingOption: "per_product",
        numTrees: 0,
        minOrderCBState: false,
        thresholdAmt: 0.0,
        showPerOrderCB: false,
        showPerOrderThreshold: false,

        // banner page
        installedBadges: [],
        selectedBadges: [],

        // global settings
        availableIntegrations: [],
        selectedIntegration: null,
        integrationStatus: 'inactive',
        activeIntegrations: [
          {
            name: "SS Site 1",
            id: "shopify",
            price: 0.50,
            status: "active",
          },
          {
            name: "SS Site 2",
            id: "shopify",
            price: 0.50,
            status:"inactive"
          }
        ],

        badgeInstalled: true,


        // metrics 
        metrics: {
          totalTreesPlantedIntegration: 0,
          totalTreesPlantedOverall: 0,
          currentPeriodCosts: 0.00,
        },

        billingDetails: {
          last4: "",
          enabled: false,
        },

        costPerTree: 0.50,
        costPerBottle: 0.08,
        
      })


      const initiateSquarespaceExtentionsSetup = async function (user, stateData) {
        if(stateData) {
          if (stateData.website_id && stateData.website_id != "") {
            var url =  await GetAddIntegrationURLWithWebsiteID(user.getIdToken(true), "squarespace", stateData.website_id)
            stateData.website_id = "" 
           window.location.href = url
          }
        }
      }

      React.useEffect(() => {
        setIsLoading(true);
        if (!user && !loading) {
          return nav("/signup", {
            state:stateData,
           }, )
        }



         initiateSquarespaceExtentionsSetup(user, stateData)
        if (stateData && stateData.stateType === "oauth") {
          setShowIntegrationModal(true);
        }
      }, [user, loading, stateData]);

      
      // initial load of everything needed ... 

      React.useEffect( () => {
        async function n(){
          if (!loading && user == null) {

              return nav("/signup", {
                state:stateData,
               }, )
          }

          if (user == null) {
              return
          }
          const integrations = await HTTPGet("/api/user/integrations", user.getIdToken(true), {})
          if (integrations.status !== 200) {
            setIsLoading(false);

            return
          }
          
      
          if (integrations.data == null) {
            integrations.data = []
          }


          const accountSettings = await GetAccountSettings(user.getIdToken(true));
          if (accountSettings == null) {
            console.log("Error loading account settings")
          }
          
      

          setCompStore({
            ...compStore,
            global_account_settings: accountSettings,
            connected_integrations: integrations.data,
            current_integration: integrations.data[0],
            availableIntegrations: integrations.data,
            selectedIntegration: integrations.data[0],
          })

          setIsLoading(false);
          try {
            if (window.$crisp) {
              window.CRISP_TOKEN_ID = accountSettings.user_email
              window.$crisp.push(["set", "user:nickname", [accountSettings.user_email]]);
              window.$crisp.push(["set", "user:email", [accountSettings.user_email]]);
              var session_data = []
              for (var i = 0; i < integrations.data.length; i++) {
                var integration_details = []
                integration_details.push(`integrations_${i}`)
                integration_details.push(integrations.data[i].url)
                session_data.push(integration_details)
                session_data.push(["current_integration", integrations.data[0].url])
                window.$crisp.push(["set", "session:data", [session_data]]);
              }
            
            }
          } catch (e) {
            console.log(e)
          }
        }
        n()


      },[user,loading,stateData]);

      React.useEffect(() => {
        if (compStore.current_integration?.url) {
          if (window.$crisp) {
            window.$crisp.push(["set", "session:data", [[[`current_integration`, compStore.current_integration.url]]]]);
          }
        }
      }, [compStore.current_integration])


     

      return (
        <>
       {showIntegetraionModal &&user && (       <IntegrationModal
            show={showIntegetraionModal}
            refComp={modalRef}
            oauthStateData={stateData}
            user={user}
          />)}

<Container maxW="1280px">
{/* <AccessDeniedModal show={true}/> */}
       <VStack ref={modalRef} align='stretch' spacing={2} divider={<Box h="10px" borderColor={'white'}/>}>
        <Box pt="24px">
        <NavBar compStore={compStore} setCompStore={setCompStore}/>
        </Box>
        {isLoading ? (
          <div  style={{ position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: "9999"}}>
        <Spinner size="xl" color="white"/>
        </div>
        
        ) :( 
          <>
        <Box>
        <Metrics compStore={compStore} setCompStore={setCompStore} />
        </Box>
        {compStore.connected_integrations.length >0 &&(
        <BillingInfoAlert compStore={compStore} setCompStore={setCompStore}/>
        )}
        <Box>
          {compStore.connected_integrations.length == 0? (
            <AddIntegrationBox/>
          ):
          (
            <CoreHeader compStore={compStore} 
            setCompStore={setCompStore} 
            appSettings={<AppSettingsV2 compStore={compStore} setCompStore={setCompStore}/>} banners={<Banners compStore={compStore} setCompStore={setCompStore}/>} billingInformation={<Billing  compStore={compStore} setCompStore={setCompStore} />}
            additionalTreesAndBottles={<AdditionalImpact compStore={compStore} setCompStore={setCompStore}/>}
             />
          )}
        </Box>
        </>
        )}
        </VStack>
        </Container>
        

       </>
    );

}

